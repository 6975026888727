import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

export default function Popup(props) {
  const {
    title,
    children,
    openPopup,
    // setOpenPopup,
    // handleClose,
    width,
    setTopPadding = false,
    topPadding,
  } = props;

  const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
      position: "absolute",
      top: theme.spacing(setTopPadding ? topPadding : 5),
      "& .MuiDialogTitle-root": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      fullWidth={true}
      maxWidth={width}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
