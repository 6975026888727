import React from "react";
import "./Login.css";

import { Navbar } from "react-bootstrap";
import { useState } from "react";
import { authenticateUser2 } from "../services/util";

import logo from "../assets/Images/Comp_logo.png";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (userName.length === 0 || password.length === 0) {
      alert("No fields should be empty!!");
      return;
    }
    getUser();
  };

  const getUser = () => {
    setIsLoading(true);
    authenticateUser2(userName, password)
      .then((response) => {
        sessionStorage.setItem(
          "getUser",
          JSON.stringify(response.data.Data.data[0])
        );

        sessionStorage.setItem("lmsAuthToken", response.data.Token);

        window.location.reload();
      })
      .catch((error) => {
        alert("Invalid credentials!");
        setIsLoading(false);
      });
  };

  const onShowPassword = () => {
    setShowPass((prevState) => !prevState);
  };

  return (
    <div className="row">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <div className="w-100">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="primary"
          sticky="top"
          variant="dark">
          <Navbar.Brand href="/">
            <span className="img_span">
              <img src={logo} className="img" alt="company_logo"></img>
            </span>
          </Navbar.Brand>
          <Navbar.Brand href="/">
            <span className="company">
              <h4>Leave Management System</h4>
            </span>
          </Navbar.Brand>
        </Navbar>
      </div>
      <div className="col-md-12">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="login-container">
            <form className="form">
              <p className="form-title">Log in to your account</p>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Enter username"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
                <span></span>
              </div>
              <div className="input-container">
                <input
                  type={showPass ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-check ml-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={onShowPassword}
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label text-muted"
                  style={{ fontSize: "13px" }}>
                  Show password
                </label>
              </div>
              <button
                type="submit"
                className="submit"
                onClick={onSubmitHandler}>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
