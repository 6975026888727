import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { Link, TableBody, TableCell, TableRow } from "@material-ui/core";
import { getEmpList, updateEmpList } from "../../services/util";
import Popup from "../../components/Popup";
import UpdateEmpList from "./UpdateEmpList";
import { useHistory } from "react-router-dom";

//CSS
import "../../assets/CSS/DownloadBtn.css";

//Images
import downloadBtn from "../../assets/Images/excel_down.png";

//To download table as Excel
import TableToExcel from "@linways/table-to-excel";

const headCells = [
  { id: "EmployeeID", title: "Employee Id", disableSorting: false },
  {
    id: "EmployeeFirstName",
    title: "First Name",
    disableSorting: false,
  },
  {
    id: "EmployeeLastName",
    title: "Last Name",
    disableSorting: false,
  },
  { id: "Project", title: "Project", disableSorting: false },
  { id: "DOJ", title: "DOJ", disableSorting: false },
  { id: "EmailID", title: "EmailID", disableSorting: false },
  { id: "Contact", title: "Contact", disableSorting: false },
  {
    id: "Reporting Manager",
    title: "Reporting Manager",
    disableSorting: false,
  },
  { id: "Role", title: "Role", disableSorting: false },
  { id: "Designation", title: "Designation", disableSorting: false },
  { id: "Status", title: "Status", disableSorting: false },
  { id: "action", title: "Action", disableSorting: true },
];

export default function EmployeeList() {
  const [records, setRecords] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  let empData = JSON.parse(sessionStorage.getItem("getUser"));

  const history = useHistory();

  useEffect(() => {
    if (empData.strRoleName !== "HR") {
      history.push("/");
      alert("Warning: un-authorized access!");
    }

    getEmpList()
      .then((response) => {
        setRecords(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  }, []);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn);

  const DateConvert = (inputDate) => {
    if (inputDate === null || inputDate === undefined || inputDate === "") {
      return "";
    } else {
      let date = new Date(inputDate);
      return new Intl.DateTimeFormat("en-IN").format(date);
    }
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.FirstName.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  const saveEmpList = async (value) => {
    setIsLoading(true);
    await updateEmpList(value)
      .then((response) => {
        //console.log("apiData", response);
        window.alert(response.data[0].Message);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
    setOpenPopup(false);
    getEmpList()
      .then((response) => {
        setRecords(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
    setIsLoading(false);
  };

  const handleDownload = (e) => {
    let table = document.getElementById("table-summary");
    TableToExcel.convert(table, {
      name: `employee_leaveSummary.xlsx`, // fileName
      sheet: {
        name: "Sheet 1", // sheetName
      },
    });
  };

  const cancelEmpList = () => {
    setOpenPopup(false);
  };

  return (
    <div className="container-fluid">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee List</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className="card-header bg-primary text-white">Employee List</div>

        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div className="col-md-3 ml-auto">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="search"
                  id="txtSearch"
                  onChange={handleSearch}></input>
              </div>
            </div>
            <div className="">
              <div className="form-group">
                <button
                  className=""
                  placeholder="download"
                  id="download"
                  title="Download as Excel Sheet"
                  onClick={handleDownload}>
                  <img
                    id="ExcelImage"
                    src={downloadBtn}
                    alt="exl_download_img"
                  />
                </button>
              </div>
            </div>
          </div>
          <TblContainer id="summary">
            <TblHead />
            <TableBody>
              {/* use recordsAfterPagingAndSorting if pagination  requried */}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.EmpID}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.EmpID}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.FirstName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.LastName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Project}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {DateConvert(item.DOJ)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.EmailId}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.ContactNo}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.ReportingManager}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.UserRole}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Designation}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.EmpStatus}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Link
                      onClick={() => {
                        setEditRecord(item);
                        setOpenPopup(true);
                      }}
                      href="#">
                      Edit
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />

          <Popup
            openPopup={openPopup}
            title={"Update Employee Details"}
            setOpenPopup={setOpenPopup}
            width={"md"}
            setTopPadding={false}>
            <UpdateEmpList
              cancelEmpList={cancelEmpList}
              editRecord={editRecord}
              saveEmpList={saveEmpList}
            />
          </Popup>
          {/* <div className="d-flex justify-content-end">
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="button"
                  className="form-control btn-primary"
                  value="Print"
                  onClick={() => generatePDFFromJSON(records)}
                ></input>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
