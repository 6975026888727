import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Slide,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    position: "absolute",
    top: theme.spacing(-1),
    "& .MuiDialogTitle-root": {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

export default function Alert(props) {
  const { title, message, openPopup, textBox, alertOk, cancel, handleClose } =
    props;
  const classes = useStyles();

  const [textValue, setTextValue] = useState("");
  const [valid, setValid] = useState(true);

  const handleTextChange = (e) => {
    if (!valid) {
      setValid((prevState) => !prevState);
    }
    setTextValue(e.target.value);
  };

  const handleOk = () => {
    if (textBox) {
      if (validate()) {
        alertOk(textValue);
        handleClose();
      }
    } else {
      alertOk(textValue);
      handleClose();
    }
  };

  const validate = () => {
    if (textValue === null || textValue === undefined || textValue === "") {
      setValid(false);
      return false;
    }
    return true;
  };

  return (
    <Dialog
      open={openPopup}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.dialogWrapper }}
      TransitionComponent={Slide}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <>
          {message !== undefined && (
            <div className="row justify-content-start">
              <p className="h6">{message}</p>
            </div>
          )}

          {textBox && (
            <div className="row mb-3">
              <input
                type="text"
                className={"form-control " + (!valid && "is-invalid")}
                value={textValue}
                onChange={handleTextChange}
              />
            </div>
          )}
          <div className="row justify-content-end">
            <div className="">
              <button
                type="button"
                className="btn btn-primary font-weight-bold button"
                onClick={handleOk}
              >
                Ok
              </button>
            </div>
            {cancel && (
              <div className="">
                <button
                  type="button"
                  className="btn btn-secondary font-weight-bold button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
}
