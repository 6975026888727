import React from "react";
import { Breadcrumb } from "react-bootstrap";

class EmployeeCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      password: "",
      gender: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  firstNameHandler = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  lastNameHandler = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  passwordHandler = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  genderHandler = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };

  handleSubmit = (event) => {
    alert("Registered Successfully!!!");
    this.setState({
      firstName: "",
      lastName: "",
      password: "",
      gender: "",
    });
    event.preventDefault();
  };

  render() {
    return (
      <div className="container-fluid">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>User Management</Breadcrumb.Item>
          <Breadcrumb.Item active>Add Employee</Breadcrumb.Item>
        </Breadcrumb>
        {/* Employee Details tab */}
        <div className="card">
          <div className="card-header bg-primary text-white">
            Employee Detail
            {/* <span className="float-right">
              <Create />
            </span> */}
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtFN">First Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter first name"
                      id="txtFN"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtLN">Last Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter last name"
                      id="txtLN"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtemail">Email Address:</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="enter email address"
                      id="txtemail"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="selGender">Gender:</label>
                    <select className="form-control" id="selGender">
                      <option>Male</option>
                      <option>Female</option>
                      <option>Transgender</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="selDesign">Designation:</label>
                    <select className="form-control" id="selDesign">
                      <option>Team Leader</option>
                      <option>Software Engineer</option>
                      <option>Manager</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtDOJ">Date of Joining:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="dd/mm/yyyy"
                      id="txtDOJ"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtMobile">Mobile No:</label>
                    <input
                      type="numeric"
                      className="form-control"
                      placeholder="enter mobile no"
                      id="txtMobile"
                    ></input>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <br />
        </div>
        {/* Personal Details tab */}
        <div className="card">
          <div className="card-header bg-primary text-white">
            Personal Detail
            {/* <span className="float-right">
              <Create />
            </span> */}
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtFatName">Father Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter father name"
                      id="txtFatName"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtMN">Mother Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter mother name"
                      id="txtMN"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtDOB">Date of Birth:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="dd/mm/yyyy"
                      id="txtDOB"
                    ></input>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="selBG">Blood Group:</label>
                    <select className="form-control" id="selBG">
                      <option>A +ve</option>
                      <option>A -ve</option>
                      <option>B +ve</option>
                      <option>B -ve</option>
                      <option>O +ve</option>
                      <option>O -ve</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="selDesign">Marital Status:</label>
                    <select className="form-control" id="selDesign">
                      <option>Single</option>
                      <option>Married</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlfor="txtEC">Emergency Contact:</label>
                    <input
                      type="numeric"
                      className="form-control"
                      placeholder="enter emergency contact"
                      id="txtEC"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlfor="txtComAdd">Communication Address:</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      placeholder="enter communication address"
                      id="txtComAdd"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlfor="txtPerAdd">Permenant Address:</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      placeholder="enter permenant address"
                      id="txtPerAdd"
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Personal Details tab End */}
        <div className="row">
          <br />
        </div>
        <div className="row justify-content-center">
          <button type="button" className="btn btn-primary font-weight-bold">
            Submit
          </button>
          <div className="gap"></div>
          <button type="button" className="btn btn-secondary font-weight-bold">
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default EmployeeCreate;
