import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { getEmployeeLeaveRequest } from "../../services/util";
import { useHistory } from "react-router-dom";

//To download table as Excel
import TableToExcel from "@linways/table-to-excel";

//Images
import downloadBtn from "../../assets/Images/excel_down.png";

//CSS
import "../../assets/CSS/DownloadBtn.css";

const headCells = [
  { id: "EmployeeId", title: "Employee ID", enableSorting: false },
  { id: "strFirstName", title: "Employee Name", enableSorting: false },
  { id: "Project", title: "Project", disableSorting: false },
  {
    id: "strLeaveName",
    title: "Leave Type",
    enableSorting: false,
  },
  {
    id: "duration",
    title: "Duration",
    enableSorting: false,
  },
  {
    id: "noOfDays",
    title: "Day(s)",
    enableSorting: false,
  },
  {
    id: "strSubject",
    title: "Subject",
    enableSorting: false,
  },
  {
    id: "strReason",
    title: "Reason",
    enableSorting: false,
  },
  {
    id: "AppName",
    title: "Approver",
    enableSorting: false,
  },
  {
    id: "strStatus",
    title: "Status",
    enableSorting: false,
  },
];

const DateConvert = (inputDate) => {
  let date = new Date(inputDate);
  return new Intl.DateTimeFormat("en-IN").format(date);
};

//Date Util Functions
// Function to get the first day of the current month
const getFirstDayOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 2)
    .toISOString()
    .split("T")[0];
};
// Function to get the current date
const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

let init = true;

export default function EmployeeLeaveRequests() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("All");

  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getCurrentDate());

  let empData = JSON.parse(sessionStorage.getItem("getUser"));

  useEffect(() => {
    if (endDate !== "") {
      reFetchData();
    } else {
      alert("Set end date!");
    }
  }, [status]);

  const reFetchData = () => {
    getEmployeeLeaveRequest(empData.strEmpID, status, startDate, endDate)
      .then((response) => {
        setRecords(response.data);
        // console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  //URL Protection Measure
  //import { useHistory } from "react-router-dom";
  const history = useHistory();
  useEffect(() => {
    if (empData.strRoleName !== "HR" && empData.strRoleName !== "Manager") {
      history.push("/");
      alert("Warning: un-authorized access!");
    }
  }, []);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn);

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setStatus(value);
  };

  const handleSearch = (e) => {
    let target = e.target;
    console.log(e.target.value);
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.strFirstName.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  const handleDateFilter = () => {
    // setFilterFn({
    //   fn: (items) => {
    //     if (startDate === "" && endDate === "") return items;
    //     return items.filter(
    //       (x) =>
    //         new Date(x.dtStartDate) >= new Date(startDate) &&
    //         new Date(x.dtEndDate) <= new Date(endDate)
    //     );
    //   },
    // });
    if (endDate !== "") {
      getEmployeeLeaveRequest(empData.strEmpID, status, startDate, endDate)
        .then((response) => {
          setRecords(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error.message);
        });
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    // setEndDate("");
    setIsLoading(true);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setIsLoading(true);
    //reFetchData();
  };

  useEffect(() => {
    if (init === true) {
      init = false;
      return;
    }
    const dateY = new Date(endDate);
    const dateX = new Date(startDate);

    if (dateY >= dateX && endDate !== "" && startDate !== "") {
      handleDateFilter();
    } else {
      setIsLoading(false);
    }
  }, [endDate, startDate]);

  // useEffect(() => {
  //   if (init === true) {
  //     init = false;
  //     return;
  //   }
  //   handleDateFilter();
  // }, [endDate]);

  const handleDownload = (e) => {
    let table = document.getElementById("table-plans");
    TableToExcel.convert(table, {
      name: `employee_leavePlans.xlsx`, // fileName
      sheet: {
        name: "Sheet 1", // sheetName
      },
    });
  };

  const DateConvert = (inputDate) => {
    let date = new Date(inputDate);
    return new Intl.DateTimeFormat("en-IN").format(date);
  };

  return (
    <div className="container-fluid">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee Leave Requests</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className="card-header bg-primary text-white">
          Employee Leave Requests
        </div>

        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div className="row row-cols-lg-auto g-3">
              <label htmlFor="fromDate" className="ml-3 p-1 text-muted">
                From Date:
              </label>
              <div className="form-group">
                <input
                  type="date"
                  id="fromDate"
                  name="fromDate"
                  value={startDate}
                  max={endDate}
                  onChange={handleStartDateChange}
                  className="form-control ml-1"
                />
              </div>
              <label htmlFor="toDate" className="ml-4 p-1 text-muted">
                To Date:
              </label>
              <div className="form-group">
                <input
                  type="date"
                  id="toDate"
                  name="toDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  min={startDate}
                  className="form-control ml-1"
                />
              </div>
              <label htmlFor="toDate" className="ml-4 p-1 text-muted">
                Status:
              </label>
              <div className="form-group">
                <select
                  className="form-control ml-1"
                  value={status}
                  onChange={handleStatusChange}
                  id="status">
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Un-Approved">Un-Approved</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div className="col-md-2">
                {records.length < 1 ? (
                  <span className="noRecord">No records found...</span>
                ) : null}
              </div>
            </div>

            <div className="col-md-3 ml-auto">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="search"
                  id="txtSearch"
                  onChange={handleSearch}></input>
              </div>
            </div>
            <div className="">
              <div className="form-group">
                <button
                  className=""
                  placeholder="download"
                  id="download"
                  title="Download as Excel Sheet"
                  onClick={handleDownload}>
                  <img
                    id="ExcelImage"
                    src={downloadBtn}
                    alt="exl_download_img"
                  />
                </button>
              </div>
            </div>
          </div>
          <TblContainer id="plans">
            <TblHead />
            <TableBody>
              {/* use recordsAfterPagingAndSorting if pagination  requried */}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.idLeaveRequest}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.EmpID}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strFirstName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Project}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strLeaveName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {DateConvert(item.dtStartDate) +
                      " - " +
                      DateConvert(item.dtEndDate)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Days}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strSubject}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strReason}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.AppName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strStatus}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </div>
      </div>
    </div>
  );
}
