import React, { useState } from "react";
import moment from "moment";

const initialValues = {
  idLeavePlan: "",
  dtStartDate: "",
  dtEndDate: "",
  strReason: "",
  empId: "",
};

export default function UpdateLeavePlan(props) {
  const {
    employeeId,
    cancelLeavePlan,
    editLeaveRecord,
    addLeavePlan,
    saveLeavePlan,
  } = props;

  initialValues.empId = employeeId;

  const [values, setValues] = useState(
    editLeaveRecord != null ? editLeaveRecord : initialValues
  );

  const validate = () => {
    let temp = {};
    temp.dtStartDate =
      values.dtStartDate.length !== 0 ? "" : "This field is required.";
    temp.dtEndDate =
      values.dtEndDate.length !== 0 ? "" : "This field is required.";
    temp.strReason =
      values.strReason.length !== 0 ? "" : "This field is required.";
    //setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (window.confirm("Are you sure to save this?")) {
        addLeavePlan(values);
      }
    } else {
      alert("All the fields are mandatory!");
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (validate()) {
      if (window.confirm("Are you sure to save this?")) {
        //console.log("update LeavePlan - Pop up", values);
        saveLeavePlan(values);
      }
    } else {
      alert("All the fields are mandatory!");
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancelLeavePlan();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "dtStartDate") {
      setValues({ ...values, dtStartDate: value, dtEndDate: "" });
    } else {
      setValues({
        ...values,
        [id]: value,
      });
    }
    //if (validateOnChange) validate({ [name]: value });
  };

  const DateConvert = (inputDate) => {
    let date = new Date(inputDate);
    //return new Intl.DateTimeFormat("en-IN").format(date);
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <form>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="dtStartDate">Start Date:</label>
            <input
              type="date"
              className="form-control"
              placeholder="dd/mm/yyyy"
              id="dtStartDate"
              min={DateConvert(new Date())}
              value={DateConvert(values.dtStartDate)}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="dtEndDate">End Date:</label>
            <input
              type="date"
              className="form-control"
              placeholder="dd/mm/yyyy"
              id="dtEndDate"
              min={DateConvert(
                values.dtStartDate === "" ? new Date() : values.dtStartDate
              )}
              value={DateConvert(values.dtEndDate)}
              onChange={handleInputChange}></input>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-start">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="strReason">Reason:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Reason"
              value={values.strReason}
              onChange={handleInputChange}
              id="strReason"></input>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-end">
        <div className="col-md-12">
          {editLeaveRecord != null ? (
            <button
              type="button"
              className="btn btn-primary font-weight-bold button"
              onClick={handleSave}>
              Save
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary font-weight-bold button"
              onClick={handleSubmit}>
              Submit
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary font-weight-bold button"
            onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}
