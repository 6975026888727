import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { Link, TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  insertLeavePlan,
  deleteLeavePlan,
  getMyLeavePlans,
  updateLeavePlan,
  getYearsPlans,
} from "../../services/util";
import Popup from "../../components/Popup";
import UpdateLeavePlan from "./UpdateLeavePlan";

const headCells = [
  { id: "strFirstName", title: "Employee Name", disableSorting: false },
  {
    id: "dtStartDate",
    title: "Start Date",
    disableSorting: true,
  },
  { id: "dtEndDate", title: "End Date", disableSorting: true },
  {
    id: "strReason",
    title: "Reason",
    disableSorting: true,
  },
  { id: "action", title: "Action", disableSorting: true },
];

let empData = JSON.parse(sessionStorage.getItem("getUser"));
let firstFlag = true;

export default function LeavePlans() {
  const [records, setRecords] = useState([]);
  const [editLeaveRecord, setLeaveRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  const [yearsInDb, setYearsInDb] = useState([]);
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(
    today.getFullYear().toString()
  );

  useEffect(() => {
    if (firstFlag) {
      getYearsPlans()
        .then((response) => {
          setYearsInDb(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      firstFlag = false;
    }
    getMyLeavePlans(empData.strEmpID, selectedYear)
      .then((response) => {
        setRecords(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  }, []);

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn);

  const addLeavePlan = async (value) => {
    setIsLoading(true);
    await insertLeavePlan(value)
      .then((response) => {
        //console.log("apiData", response);
        if (response.data[0].Result === "Success") {
          window.alert(response.data[0].Message);
          refetchData();
          setLeaveRecord(null);
          setOpenPopup(false);
        } else {
          window.alert(response.data[0].Message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const refetchData = () => {
    getMyLeavePlans(empData.strEmpID, selectedYear)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const saveLeavePlan = async (value) => {
    setIsLoading(true);
    await updateLeavePlan(value)
      .then((response) => {
        if (response.data[0].Result === "Success") {
          window.alert(response.data[0].Message);
          setLeaveRecord(null);
          setOpenPopup(false);
          refetchData();
        } else {
          window.alert(response.data[0].Message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const deleteLeave = async (id) => {
    let data = {
      empId: empData.strEmpID,
      idReq: id,
    };
    setIsLoading(true);
    deleteLeavePlan(data)
      .then((response) => {
        window.alert("Record deleted Succefully!");
        //console.log("Record deleted Succefully");
      })
      .catch((error) => {
        alert(error.message);
      });

    await getMyLeavePlans(empData.strEmpID, selectedYear)
      .then((response) => {
        setRecords(response.data);
        // console.log("api res", response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });

    setIsLoading(false);
    window.location.reload();
  };

  const cancelLeavePlan = () => {
    setLeaveRecord(null);
    setOpenPopup(false);
  };

  const DateConvert = (inputDate) => {
    let date = new Date(inputDate);
    return new Intl.DateTimeFormat("en-IN").format(date);
  };

  const handleYearChange = (event) => {
    setIsLoading(true);
    const { value } = event.target;
    setSelectedYear(value);
    getMyLeavePlans(empData.strEmpID, value)
      .then((response) => {
        setRecords(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  return (
    <div className="container-fluid">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>My Leave Plans</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className="card-header bg-primary text-white">My Leave Plans</div>
        <div className="card-body ">
          <div className="row">
            <label htmlFor="year" className="ml-4 p-1 text-muted">
              Year:
            </label>
            <div className="form-group">
              <select
                className="form-control ml-1"
                value={selectedYear}
                onChange={handleYearChange}
                id="year">
                {yearsInDb.map((item) => (
                  <option key={item.Year} value={item.Year}>
                    {item.Year}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6" style={{ paddingLeft: "0px" }}>
              {records.length < 1 ? (
                <span className="noRecord">No records found...</span>
              ) : null}
            </div>
            <div className="form-group ml-auto mr-3">
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => {
                  setOpenPopup(true);
                }}>
                Add Leave Plan
              </button>
            </div>
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {/* use recordsAfterPagingAndSorting if pagination  requried */}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.idLeavePlan}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strFirstName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {DateConvert(item.dtStartDate)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {DateConvert(item.dtEndDate)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strReason}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Link
                      onClick={() => {
                        setLeaveRecord(item);
                        setOpenPopup(true);
                      }}
                      href="#">
                      Edit
                    </Link>
                    <span> / </span>
                    <Link
                      href={"#"}
                      onClick={(e) => {
                        if (
                          window.confirm("Are you sure want to delete request?")
                        ) {
                          deleteLeave(item.idLeavePlan);
                        }
                      }}>
                      Delete
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />

          <Popup
            openPopup={openPopup}
            title={"Leave Plan"}
            setOpenPopup={setOpenPopup}
            width={"sm"}
            setTopPadding={false}>
            <UpdateLeavePlan
              employeeId={empData.strEmpID}
              cancelLeavePlan={cancelLeavePlan}
              editLeaveRecord={editLeaveRecord}
              addLeavePlan={addLeavePlan}
              saveLeavePlan={saveLeavePlan}
            />
          </Popup>
        </div>
      </div>
    </div>
  );
}
