import React from "react";

import { loadDashboard } from "../services/util";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empCount: "",
      birthdayList: [],
      maleCount: "",
      femaleCount: "",
      holidayList: [],
      todayLeaveList: [],
      upcomingLeaveList: [],
      leaveCount: [],
      isLoading: false,
      user: [],
    };
  }
  componentDidMount() {
    const userDetails = JSON.parse(sessionStorage.getItem("getUser"));
    this.setState({ user: userDetails });
    //console.log(userDetails.strEmpID);
    this.loadApi();
  }

  loadApi = async () => {
    this.setState({ isLoading: true });
    let data = JSON.parse(sessionStorage.getItem("getUser"));
    await loadDashboard(data.strEmpID)
      .then((response) => {
        //console.log(response.data);
        this.setState({ empCount: response.data.empCount[0]["EmpCount"] });
        this.setState({ birthdayList: response.data.birthdayList });
        this.setState({
          maleCount: response.data.genderCount[1]["GenCount"],
        });
        this.setState({
          femaleCount: response.data.genderCount[0]["GenCount"],
        });
        this.setState({
          holidayList: response.data.holidayList,
        });
        this.setState({
          todayLeaveList: response.data.todayLeaveList,
        });
        this.setState({
          upcomingLeaveList: response.data.upcomingLeaveList,
        });
        this.setState({
          leaveCount: response.data.leaveCount[0],
        });
      })
      .catch((error) => {
        alert("New..." + error.message);
        this.setState({ isLoading: false });
      });
    this.setState({ isLoading: false });
  };
  //Format date functions
  DateConvert(inputDate) {
    let date = new Date(inputDate);
    return new Intl.DateTimeFormat("en-IN").format(date).toString();
  }

  formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    let daySuffix;

    // Determine the day suffix (st, nd, rd, or th)
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    return `${month} ${day}${daySuffix}`;
  }

  render() {
    if (sessionStorage.getItem("getUser") == null) {
      return <div />;
    }
    return (
      <div className="container-fluid">
        <div
          className={this.state.isLoading === true ? "overlay" : "hideLoading"}
        >
          <div className="loader">Loading...</div>
        </div>
        <div className="row  justify-content-center">
          <div className="col-md-12">
            <h5>Dashboard</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Leaves
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.leaveCount.TotalLeaveCnt}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Casual Leaves
                      <span style={{ fontSize: "10px" }}> (balance)</span>
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.leaveCount.CasualLeaveCnt}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Casual Leave
                    </div>
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                          {this.state.leaveCount.CasualLeaveCnt + "%"}
                        </div>
                      </div>
                      <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{
                              width: this.state.leaveCount.CasualLeaveCnt + "%",
                            }}
                            aria-valuenow={this.state.leaveCount.CasualLeaveCnt}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-warning shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Earned Leave
                    </div>
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                          {this.state.leaveCount.EarnedLeaveCnt + "%"}
                        </div>
                      </div>
                      <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            style={{
                              width: this.state.leaveCount.EarnedLeaveCnt + "%",
                            }}
                            aria-valuenow={this.state.leaveCount.EarnedLeaveCnt}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                  </div> ,
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Earned Leaves
                      <span style={{ fontSize: "10px" }}> (balance)</span>
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.leaveCount.EarnedLeaveCnt}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-danger shadow">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Leave Balance
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {this.state.isLoading
                        ? ""
                        : (
                            this.state.leaveCount.CasualLeaveCnt +
                            this.state.leaveCount.EarnedLeaveCnt
                          ).toString()}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3 mb-4">
            <div className="card bg-primary text-white shadow">
              <div className="card-body">
                <span className="h4">{this.state.empCount}</span>
                <div className="text-white">Employee Count</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-4">
            <div className="card bg-success text-white shadow">
              <div className="card-body">
                <span className="h4">{this.state.maleCount} </span>
                <div className="text-white">Male Count</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-4">
            <div className="card bg-warning text-white shadow">
              <div className="card-body">
                <span className="h4">{this.state.femaleCount}</span>
                <div className="text-white">Female Count</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-4">
            <div className="card bg-danger text-white shadow">
              <div className="card-body">
                <span className="h4">06 </span>
                <div className="text-white">Project Count</div>
              </div>
            </div>
          </div>
        </div> */}
        <hr />
        <div className="row">
          <div className="col-md-12">
            <h5>Leaves & Holidays</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card shadow" style={{ height: "100%" }}>
              <div className="card-header bg-secondary text-white">
                Today's Leaves
              </div>
              <div className="card-body scroll">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Emp Name</th>
                      {/* <th scope="col">Subject</th> */}
                      <th scope="col">Date</th>
                      <th scope="col">Days</th>
                      {/* <th scope="col">Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.todayLeaveList &&
                      this.state.todayLeaveList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.strFirstName}</td>
                            {/* <td>{item.strSubject}</td> */}
                            <td>{this.DateConvert(item.dtStartDate)}</td>
                            <td>{item.days}</td>
                            {/* <td
                              style={{
                                backgroundColor:
                                  item.strStatus === "Approved"
                                    ? "lightgreen"
                                    : "lightgray",
                              }}
                            >
                              {item.strStatus}
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card shadow" style={{ height: "100%" }}>
              <div className="card-header bg-success text-white">
                Upcoming Holidays
              </div>
              <div className="card-body scroll">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Holiday Name</th>
                      <th scope="col">Holiday Date</th>
                      <th scope="col">Day</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.holidayList &&
                      this.state.holidayList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.strHolidayName}</td>
                            <td>{this.DateConvert(item.dtHolidayDate)}</td>
                            <td>{item.day}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6">
            <div className="card shadow" style={{ height: "100%" }}>
              <div className="card-header bg-secondary text-white">
                Upcoming Leaves
              </div>
              <div className="card-body scroll">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Emp Name</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Date</th>
                      <th scope="col">Days</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.upcomingLeaveList &&
                      this.state.upcomingLeaveList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.strFirstName}</td>
                            <td>{item.strSubject}</td>
                            <td>{this.DateConvert(item.dtStartDate)}</td>
                            <td>{item.days}</td>
                            <td
                              style={{
                                backgroundColor:
                                  item.strStatus === "Approved"
                                    ? "lightgreen"
                                    : "lightgray",
                              }}
                            >
                              {item.strStatus}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card shadow" style={{ height: "100%" }}>
              <div className="card-header bg-warning text-white">
                Upcoming Birthdays
              </div>
              <div className="card-body scroll">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Emp Name</th>
                      <th scope="col">Birthday Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.birthdayList &&
                      this.state.birthdayList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.strFirstName}</td>
                            <td>{this.formatDate(item.BirthdayDate)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default HomePage;
