import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { Link, TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  getLeaveRequest,
  saveLeaveStatus,
  getYearsRequests,
} from "../../services/util";
import Alert from "../../components/Alert";
import { useHistory } from "react-router-dom";

const headCells = [
  { id: "strFirstName", title: "Employee Name", enableSorting: true },
  {
    id: "strLeaveName",
    title: "Leave Type",
    enableSorting: false,
  },
  {
    id: "duration",
    title: "Duration",
    enableSorting: false,
  },
  {
    id: "noOfDays",
    title: "Day(s)",
    enableSorting: false,
  },
  {
    id: "strSubject",
    title: "Subject",
    enableSorting: false,
  },
  {
    id: "strReason",
    title: "Reason",
    enableSorting: false,
  },
  {
    id: "AppName",
    title: "Approver",
    enableSorting: false,
  },
  {
    id: "strStatus",
    title: "Status",
    enableSorting: false,
  },
  {
    id: "action",
    title: "Actions",
    enableSorting: false,
  },
];

const DateConvert = (inputDate) => {
  let date = new Date(inputDate);
  return new Intl.DateTimeFormat("en-IN").format(date);
};

const userDetails = JSON.parse(sessionStorage.getItem("getUser"));
let firstFlag = true;

export default function ApproveLeave() {
  const [status, setStatus] = useState("Pending");
  const [records, setRecords] = useState([]);
  const [, setInputVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //Custon Alert Box States
  const [openNotification, setOpenNotification] = useState(false);
  const [textFromAlert, setTextFromAlert] = useState("");
  const [alertProps, setAlertProps] = useState({
    title: "",
    message: "",
    textBox: false,
    alertOk: () => {},
    cancel: false,
    handleClose: () => {},
  });

  const [yearsInDb, setYearsInDb] = useState([]);
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(
    today.getFullYear().toString()
  );

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  //URL Protection Measure
  //import { useHistory } from "react-router-dom";
  const history = useHistory();
  useEffect(() => {
    if (userDetails.strRoleName === "User") {
      history.push("/");
      alert("Warning: un-authorized access!");
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (firstFlag) {
      //console.log("re-re-gyr");
      getYearsRequests()
        .then((response) => {
          setYearsInDb(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      firstFlag = false;
    }
    getLeaveRequest(userDetails.strEmpID, status, selectedYear)
      .then((response) => {
        setRecords(response.data);
        // console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [status]);

  const handleStatusChange = async (event) => {
    const { value } = event.target;
    setStatus(value);
  };

  const handleYearChange = (event) => {
    const { value } = event.target;
    setSelectedYear(value);
  };

  let data = {};
  const handleAlertOk = (value) => {
    setTextFromAlert(value);
    data = { ...data, comment: value };
    updateStatus();
  };

  const statusLeave = (status, id) => {
    if (status === "Un-Approved") {
      setAlertProps({
        title: "Are you sure?",
        message: "Reason for Unapproval:",
        textBox: true,
        alertOk: handleAlertOk,
        cancel: true,
        handleClose: () => setOpenNotification((prevState) => !prevState),
      });
      setOpenNotification(() => setOpenNotification((prevState) => !prevState));
    } else {
      setAlertProps({
        title: "Are you sure?",
        message: "Do you want to approve?",
        textBox: false,
        alertOk: handleAlertOk,
        cancel: true,
        handleClose: () => setOpenNotification((prevState) => !prevState),
      });
      setOpenNotification(() => setOpenNotification((prevState) => !prevState));
    }
    data = {
      idReq: id,
      empId: userDetails.strEmpID,
      status: status,
    };
  };

  const updateStatus = () => {
    setIsLoading(true);
    saveLeaveStatus(data)
      .then((response) => {
        alert("Successfull!");
        reFetchData();
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error.message);
        setIsLoading(false);
      });
    //window.location.reload();
  };

  const reFetchData = () => {
    getLeaveRequest(userDetails.strEmpID, status, selectedYear)
      .then((response) => {
        setRecords(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn);

  return (
    <div className="container-fluid">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>Approve Leave</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className={isLoading ? "overlay" : "hideLoading"}>
          <div className="loader">Loading...</div>
        </div>
        <div className="card-header bg-primary text-white">Leave Request</div>
        <div className="card-body ">
          <div className="row">
            <label htmlFor="year" className="ml-4 p-1 text-muted">
              Year:
            </label>
            <div className="form-group">
              <select
                className="form-control ml-1"
                value={selectedYear}
                onChange={handleYearChange}
                id="year">
                {yearsInDb.map((item) => (
                  <option key={item.Year} value={item.Year}>
                    {item.Year}
                  </option>
                ))}
              </select>
            </div>

            <label htmlFor="status" className="ml-4 p-1 text-muted">
              Status:
            </label>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={status}
                  onChange={handleStatusChange}
                  id="status">
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Un-Approved">Un-Approved</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
            <div className="col-md-6" style={{ paddingLeft: "0px" }}>
              {records.length < 1 ? (
                <span className="noRecord">No records found...</span>
              ) : null}
            </div>
          </div>
          <div className="table-responsive">
            <TblContainer>
              <TblHead />
              <TableBody>
                {/* use recordsAfterPagingAndSorting() if pagination  requried */}
                {recordsAfterPagingAndSorting().map((item) => {
                  // var newDate = new Date(item.HolidayDate);
                  // var newDay = newDate.getDay();
                  return (
                    <TableRow key={item.idLeaveRequest}>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strFirstName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strLeaveName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {DateConvert(item.dtStartDate) +
                          " - " +
                          DateConvert(item.dtEndDate)}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.Days}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strSubject}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strReason}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.AppName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strStatus}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.strStatus === "Approved" ||
                        item.strStatus === "Cancelled" ? (
                          // <Link
                          //   href={"#"}
                          //   onClick={(e) => {
                          //     if (
                          //       window.confirm("Are you sure want to cancel?")
                          //     ) {
                          //       statusLeave("Cancelled", item.idLeaveRequest);
                          //     }
                          //   }}
                          // >
                          //   Cancel
                          // </Link>
                          ""
                        ) : (
                          <Link
                            href={"#"}
                            onClick={(e) => {
                              statusLeave("Approved", item.idLeaveRequest);
                            }}>
                            Approve
                          </Link>
                        )}
                        {item.strStatus === "Pending" && <b> / </b>}
                        {item.strStatus === "Un-Approved" ||
                        item.strStatus === "Cancelled" ? (
                          ""
                        ) : (
                          <Link
                            href={"#"}
                            onClick={(e) => {
                              statusLeave("Un-Approved", item.idLeaveRequest);
                            }}>
                            Un-Approve
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TblContainer>
            <TblPagination />
            {openNotification && (
              <Alert
                openPopup={openNotification}
                title={alertProps.title}
                message={alertProps.message}
                textBox={alertProps.textBox}
                alertOk={alertProps.alertOk}
                cancel={alertProps.cancel}
                handleClose={alertProps.handleClose}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
