import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   table: {
//     minWidth: 650,
//     "& .MuiTableCell-root": {
//       padding: "5px",
//     },
//     "& thead th": {
//       fontSize: "14px",
//       color: "#fff",
//       backgroundColor: "#007bff",
//       border: "1px solid lightgrey",
//       borderCollapse: "collapse",
//       textAlign: "center",
//       whiteSpace: "nowrap",
//     },
//     "& tbody td": {
//       fontSize: "14px",
//       border: "1px solid lightgrey",
//       borderCollapse: "collapse",
//     },
//     "& tbody tr:hover": {
//       backgroundColor: "#fffbf2",
//     },
//   },
// }));

export default function CustomTable(
  records,
  headCells,
  filterFn,
  padding = "5px"
) {
  //Styling
  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
      "& .MuiTableCell-root": {
        padding: padding,
      },
      "& thead th": {
        fontSize: "14px",
        color: "#fff",
        backgroundColor: "#007bff",
        border: "1px solid lightgrey",
        borderCollapse: "collapse",
        textAlign: "center",
        whiteSpace: "nowrap",
      },
      "& tbody td": {
        fontSize: "14px",
        border: "1px solid lightgrey",
        borderCollapse: "collapse",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
      },
    },
  }));

  const classes = useStyles();

  const pages = [10, 20, { value: records.length, label: "All" }];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <TableContainer component="div">
      <Table
        className={classes.table}
        aria-label="simple table"
        id={"table-" + props.id}
      >
        {props.children}
      </Table>
    </TableContainer>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((items) => (
            <TableCell
              key={items.id}
              sortDirection={orderBy === items.id ? order : false}
            >
              {items.enableSorting ? (
                <TableSortLabel
                  active={orderBy === items.id}
                  direction={orderBy === items.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(items.id);
                  }}
                >
                  {items.title}
                </TableSortLabel>
              ) : (
                items.title
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComaparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComaparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
