import React, { useState } from "react";
import { Switch } from "@material-ui/core";

export default function UpdateLeaveSummary(props) {
  const { cancelLeaveSummary, editSummaryRecord, saveLeaveSummary } = props;

  const [values, setValues] = useState(editSummaryRecord);

  const handleSave = (e) => {
    e.preventDefault();
    // if (validate()) {
    if (window.confirm("Are you sure to save this?")) {
      //console.log(values);
      //saveLeaveSummary(values);
    }
    // }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancelLeaveSummary();
  };

  const handleToggleChange = (e) => {
    const { id, checked } = e.target;
    setValues({
      ...values,
      [id]: checked,
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      [id]: value,
    });
    //if (validateOnChange) validate({ [name]: value });
  };

  // const DateConvert = (inputDate) => {
  //   let date = new Date(inputDate);
  //   //return new Intl.DateTimeFormat("en-IN").format(date);
  //   return moment(date).format("YYYY-MM-DD");
  // };

  return (
    <form>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="EmpName">Employee Name:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Employee Name"
              readOnly={true}
              id="EmpName"
              value={values.EmpName}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <p>
            <small>Disable / Enable</small>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="CasualLeave">Casual Leave:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Casual Leave Count"
              id="Casual Leave"
              readOnly={!values.cl_applicable}
              value={values["Casual Leave"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="cl_applicable"
            color="primary"
            checked={values.cl_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Earned Leave">Earned Leave:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Earned Leave Count"
              readOnly={!values.el_applicable}
              value={values["Earned Leave"]}
              onChange={handleInputChange}
              id="Earned Leave"></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="el_applicable"
            color="primary"
            checked={values.el_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Loss of Pay">Loss of pay:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Loss of Pay Count"
              id="Loss of Pay"
              readOnly={!values.lop_applicable}
              value={values["Loss of Pay"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="lop_applicable"
            color="primary"
            checked={values.lop_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Maternity">Maternity Leave:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Maternity Leave Count"
              id="Maternity"
              readOnly={!values.mat_applicable}
              value={values["Maternity"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="mat_applicable"
            color="primary"
            checked={values.mat_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Paternity">Paternity Leave:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Paternity Leave Count"
              id="Paternity"
              readOnly={!values.pat_applicable}
              value={values["Paternity"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="pat_applicable"
            color="primary"
            checked={values.pat_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Bereavement">Bereavement Leave:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Bereavement Leave Count"
              id="Bereavement"
              readOnly={!values.b_applicable}
              value={values["Bereavement"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="b_applicable"
            color="primary"
            checked={values.b_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Floating holiday">Floating Holiday:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Floating Holiday Count"
              id="Floating holiday"
              readOnly={!values.fh_applicable}
              value={values["Floating holiday"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="fh_applicable"
            color="primary"
            checked={values.fh_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="Comp off">Comp Off:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Comp Off"
              id="Comp off"
              readOnly={!values.comp_applicable}
              value={values["Comp off"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2">
          <Switch
            id="comp_applicable"
            color="primary"
            checked={values.comp_applicable}
            onChange={handleToggleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="comp_ExpiryDate">Comp Off Expiry Date:</label>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="date"
              className="form-control"
              id="comp_ExpiryDate"
              readOnly={!values.comp_applicable}
              value={values["comp_ExpiryDate"]}
              onChange={handleInputChange}></input>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-3" style={{ marginTop: "30px" }}>
          <button
            type="button"
            className="btn btn-primary font-weight-bold button"
            onClick={handleSave}>
            Save
          </button>
        </div>
        <div className="col-md-3" style={{ marginTop: "30px" }}>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold button"
            onClick={handleCancel}>
            Cancel
          </button>
        </div>
        <div className="col-md-2"></div>
      </div>
    </form>
  );
}
