import React, { useState } from "react";

import "./ChangePassword.css";
import { Navbar, Nav } from "react-bootstrap";
import { changePassword } from "../../services/util";

import logo from "../../assets/Images/Comp_logo.png";

const ChangePassword2 = () => {
  const [isWrong, setIsWrong] = useState(false);
  const [message, setMessage] = useState("Message!");
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confrimPass, setConfrimPass] = useState("");
  const [isDone, setIsDone] = useState(false);

  const [timer, setTimer] = useState(3);
  const [isLoading, setIsLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const userData = JSON.parse(sessionStorage.getItem("getUser"));

  const logouthandler = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const handleConfirm = () => {
    if (formValidate()) {
      setIsLoading(true);
      changePassword(userData.strEmpID, currentPass, newPass)
        .then((response) => {
          const result = response.data.data[0].RESULT;
          setMessage(result);
          if (result === "Current password is incorrect") {
            setIsWrong(true);
          } else {
            setIsDone(true);
            // Display timer for 3 seconds
            let secondsRemaining = 3;
            const timerInterval = setInterval(() => {
              setTimer(secondsRemaining);
              secondsRemaining--;
              if (secondsRemaining === 0) {
                clearInterval(timerInterval);
                sessionStorage.clear();
                window.location.href = "/";
              }
            }, 1000);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error.message);
        });
    }
  };
  const handleClose = () => {
    setIsWrong((prevState) => !prevState);
  };

  //Change Handlers

  const handleCurrentPassChange = (event) => {
    setCurrentPass(event.target.value);
  };
  const handleNewPassChange = (event) => {
    setNewPass(event.target.value);
  };
  const handleConfrimPassChange = (event) => {
    setConfrimPass(event.target.value);
  };

  //Form validator
  const formValidate = () => {
    const validateLength = (value, fieldName) => {
      if (value.length < 8) {
        setMessage(`${fieldName} should be at least 8 characters long!`);
        setIsWrong(true);
        return false;
      }
      return true;
    };

    if (
      !validateLength(currentPass, "Current Password") ||
      !validateLength(newPass, "New Password") ||
      !validateLength(confrimPass, "Confirm Password")
    ) {
      return false;
    }

    if (currentPass === newPass) {
      setMessage("Enter a new password!");
      setIsWrong(true);
      return false;
    }

    if (newPass !== confrimPass) {
      setMessage("Confirm password doesn't match with the new password!");
      setIsWrong(true);
      return false;
    }

    setIsWrong(false);
    return true;
  };

  const onShowPassword = () => {
    setShowPass((prevState) => !prevState);
  };

  return (
    <>
      <div className="row">
        <div className="w-100">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="primary"
            sticky="top"
            variant="dark">
            <Navbar.Brand href="/">
              <span className="img_span">
                <img src={logo} className="img" alt="company_logo"></img>
              </span>
            </Navbar.Brand>
            <Navbar.Brand href="/">
              <span className="company">
                <h4>Leave Management System</h4>
              </span>
            </Navbar.Brand>
            <Nav className="ml-auto">
              <Nav.Link onClick={logouthandler} className="active">
                LogOut
              </Nav.Link>
            </Nav>
          </Navbar>
        </div>
      </div>
      <div className="main">
        <div className={isLoading === true ? "overlay" : "hideLoading"}>
          <div className="loader">Loading...</div>
        </div>

        <div className={isDone === true ? "overlay" : "hideLoading"}></div>
        <div className="card shadow login-form">
          <div className="card-body">
            <h3 className="card-title text-center">Change password</h3>

            <div className="card-text">
              <form className="cp_form">
                {isWrong && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert">
                    <strong>{message}</strong>. Please check below.
                    <button
                      className="close borderless"
                      aria-label="Close"
                      onClick={handleClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                {isDone && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert">
                    <strong>{message}</strong> ...Redirecting to Login Page in{" "}
                    {timer} seconds
                    <button className="close borderless" aria-label="Close">
                      <span aria-hidden="true">&#10004;</span>
                    </button>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="changePassword1">Current password</label>
                  <input
                    type={showPass ? "text" : "password"}
                    value={currentPass}
                    onChange={handleCurrentPassChange}
                    disabled={isDone}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="changePassword2">Your new password</label>
                  <input
                    type={showPass ? "text" : "password"}
                    value={newPass}
                    onChange={handleNewPassChange}
                    disabled={isDone}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="changePassword3">Confirm new password</label>
                  <input
                    type={showPass ? "text" : "password"}
                    value={confrimPass}
                    onChange={handleConfrimPassChange}
                    disabled={isDone}
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-check ml-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={onShowPassword}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label text-muted"
                    style={{ fontSize: "13px" }}>
                    Show Password
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-block submit-btn"
                  onClick={handleConfirm}>
                  Confirm
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword2;
