import React from "react";
import MenuBar from "../components/MenuBar";
import Login from "../components/Login";
import ChangePassword2 from "./Password/ChangePassword2";

function App() {
  const userDetails = JSON.parse(sessionStorage.getItem("getUser"));
  //console.log(userDetails);
  return (
    <div className="container-fluid mb-5">
      {userDetails &&
        (userDetails.isChangePassword === null ||
        userDetails.isChangePassword === 0 ? (
          <ChangePassword2 />
        ) : (
          <MenuBar />
        ))}
      {!userDetails && <Login />}
    </div>
  );
}

export default App;
