import axios from "axios";

//let baseUrl = "https://lms-core-api.azurewebsites.net/api/"; //Azure Hosted
// let baseUrl = "http://10.10.200.208:8080/api/"; //UBTI LOCAL HOSTED
let baseUrl = "http://125.20.158.210:8080/api/"; //UBTI-HOSTED Open Path
// let baseUrl = "https://localhost:7297/api/"; //.Net Core API(LocalMachine)

let auth_token = sessionStorage.getItem("lmsAuthToken");

//Get Dashboard
// export const authenticateUser = () => {
//   return axios.get(
//     baseUrl + "employee/getAuthenticate?empId=10113&pwd=ubti@123"
//   );
// };

export const authenticateUser2 = (userId, pwd) => {
  return axios.get(baseUrl + "employee/getAuthenticate/" + userId + "," + pwd);
};

export const changePassword = (empId, currentPwd, newPwd) => {
  return axios.get(
    baseUrl +
      "employee/changePassword/" +
      empId +
      "," +
      currentPwd +
      "," +
      newPwd,
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

//Get Dashboard
export const loadDashboard = (empId) => {
  return axios.get(baseUrl + "leave/getDashboard/" + empId, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Employee List
export const getEmpList = () => {
  return axios.get(baseUrl + "leave/getEmpList", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Leave Type
export const getLeaveType = (empId) => {
  return axios.get(baseUrl + "leave/getLeaveTypeList/" + empId, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Leave Type
export const getLeaveCount = (empId, leaveType) => {
  return axios.get(baseUrl + "leave/getLeaveCount/" + empId + "," + leaveType, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Save Leave Request
export const InsertLeaveRequest = (data) => {
  return axios.post(
    baseUrl + "leave/addLeaveRequest",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

//Save Leave Request
export const updateLeaveRequest = (data) => {
  return axios.post(
    baseUrl + "leave/updateLeaveRequest",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

export const saveLeaveStatus = (data) => {
  return axios.post(
    baseUrl + "leave/updateLeaveStatus",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
    // {
    //   withCredentials: true,
    // }
  );
};

//Get Leave Status
export const getLeaveStatus = (empId, status, year) => {
  return axios.get(
    baseUrl + "leave/getLeaveStatus/" + empId + "," + status + "," + year,
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

//Get my leave request
export const getLeaveRequest = (empId, status, year) => {
  return axios.get(
    baseUrl + "leave/getLeaveRequest/" + empId + "," + status + "," + year,
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
    // {
    //   withCredentials: true,
    // }
  );
};

//Get all leave request
export const getEmployeeLeaveRequest = (
  empId,
  status,
  startDate = getFirstDayOfMonth(),
  endDate = getCurrentDate()
) => {
  return axios.get(
    baseUrl +
      "leave/getEmployeeLeaveRequest/" +
      empId +
      "," +
      status +
      "," +
      startDate +
      "," +
      endDate,
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
    // {
    //   withCredentials: true,
    // }
  );
};

//Get Holiday List
export const getHolidayList = () => {
  return axios.get(baseUrl + "holiday/getHolidayList", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Floating Holiday List
export const getFloatingHolidayList = (year) => {
  return axios.get(baseUrl + "holiday/getFloatingHolidayList/" + year, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Leave Summary
export const getEmployeeLeaveSummary = (year) => {
  return axios.get(baseUrl + "leave/getEmployeeLeaveSummary/" + year, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Leave Summary_New
export const getEmployeeLeaveSummary_New = (year) => {
  return axios.get(baseUrl + "leave/getEmployeeLeaveSummary_New/" + year, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//update Leave Summary
export const updateLeaveSummary = (data) => {
  return axios.post(
    baseUrl + "leave/updateEmployeeLeaveSummary",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

//Get My Leave Plans
export const getMyLeavePlans = (empId, year) => {
  return axios.get(baseUrl + "leave/getMyLeavePlans/" + empId + "," + year, {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Date Util Functions
// Function to get the first day of the current month
const getFirstDayOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1)
    .toISOString()
    .split("T")[0];
};
// Function to get the current date
const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

//Get Leave Plans
export const getLeavePlans = (
  empId,
  startDate = getFirstDayOfMonth(),
  endDate = getCurrentDate()
) => {
  return axios.get(
    baseUrl + "leave/getLeavePlans/" + empId + "," + startDate + "," + endDate,
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

export const insertLeavePlan = (data) => {
  return axios.post(
    baseUrl + "leave/insertLeavePlans",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

export const updateLeavePlan = (data) => {
  return axios.post(
    baseUrl + "leave/updateLeavePlans",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

export const deleteLeavePlan = (data) => {
  return axios.post(
    baseUrl + "leave/deleteLeavePlan",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};

//For filter
//Get Leave Req Years
export const getYearsRequests = () => {
  return axios.get(baseUrl + "leave/getYearsRequests", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};
//Get Leave Plan Years
export const getYearsPlans = () => {
  return axios.get(baseUrl + "leave/getYearsPlans", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get summary Years
export const getYearsSummary = () => {
  return axios.get(baseUrl + "leave/getYearsSummary", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Get Upd Emp DropDown
export const getUpdEmpDropdown = () => {
  return axios.get(baseUrl + "leave/getUpdEmpDropdown", {
    headers: {
      Authorization: `Bearer ${auth_token}`,
    },
  });
};

//Update Employee List
export const updateEmpList = (data) => {
  return axios.post(
    baseUrl + "leave/updateEmpList",
    { data },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    }
  );
};
