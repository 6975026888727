import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { Link, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import {
  getEmployeeLeaveSummary,
  getEmployeeLeaveSummary_New,
  updateLeaveSummary,
  getYearsSummary,
} from "../../services/util";
import Popup from "../../components/Popup";
import UpdateLeaveSummary from "./UpdateLeaveSummary";
import { useHistory } from "react-router-dom";

//CSS
import "../../assets/CSS/DownloadBtn.css";

//Images
import downloadBtn from "../../assets/Images/excel_down.png";

//To download table as Excel
import TableToExcel from "@linways/table-to-excel";

// import generatePDFFromJSON from "../../pdfConvertFunction";

// const headCells = [
//   { id: "EmployeeId", title: "Employee ID", disableSorting: false },
//   { id: "EmployeeFirstName", title: "Employee Name", disableSorting: false },
//   { id: "Project", title: "Project", disableSorting: false },
//   {
//     id: "casualLeave",
//     title: "Casual Leave",
//     disableSorting: true,
//   },
//   { id: "currentEarned", title: "Current Earned", disableSorting: true },
//   {
//     id: "overallEarned",
//     title: "Overall Earned",
//     disableSorting: true,
//   },
//   {
//     id: "comment",
//     title: "Comment",
//     disableSorting: true,
//   },
//   { id: "action", title: "Action", disableSorting: true },
// ];

const headCells = [
  { id: "EmployeeId", title: "Employee ID", disableSorting: false },
  { id: "EmployeeName", title: "Employee Name", disableSorting: false },
  { id: "Project", title: "Project", disableSorting: false },
  {
    id: "casualLeave",
    title: "Casual Leave",
    disableSorting: true,
  },
  { id: "earnedLeave", title: "Earned Leave", disableSorting: true },
  { id: "lossOfPay", title: "Loss of Pay", disableSorting: true },
  { id: "maternity", title: "Maternity", disableSorting: true },
  { id: "paternity", title: "Paternity", disableSorting: true },
  { id: "bereavement", title: "Bereavement", disableSorting: true },
  { id: "floatingHoliday", title: "Floating Holiday", disableSorting: true },
  { id: "compOffDate", title: "Comp Off (Expiry Date)", disableSorting: true },
  //{ id: "action", title: "Action", disableSorting: true },
];

let firstFlag = true;

export default function LeaveSummary() {
  const [records, setRecords] = useState([]);
  const [editSummaryRecord, setSummaryRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  const [yearsInDb, setYearsInDb] = useState([]);
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(
    today.getFullYear().toString()
  );
  //const [selectedYear, setSelectedYear] = useState("2023");

  let empData = JSON.parse(sessionStorage.getItem("getUser"));
  useEffect(() => {
    if (firstFlag) {
      getYearsSummary()
        .then((response) => {
          console.log(response.data);
          setYearsInDb(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      firstFlag = false;
    }

    getEmployeeLeaveSummary_New(selectedYear)
      .then((response) => {
        //console.log(response.data);
        setRecords(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });

    // getEmployeeLeaveSummary(selectedYear)
    //   .then((response) => {
    //     setRecords(response.data);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     alert(error.message);
    //   });
  }, [selectedYear]);

  //URL Protection Measure
  //import { useHistory } from "react-router-dom";
  const history = useHistory();
  useEffect(() => {
    if (empData.strRoleName !== "HR") {
      history.push("/");
      alert("Warning: un-authorized access!");
    }
  }, []);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const tablePadding = "0.5px";
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn, tablePadding);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.EmpName.toLowerCase().includes(target.value.toLowerCase())
          );
      },
    });
  };

  const saveLeaveSummary = async (value) => {
    setIsLoading(true);
    await updateLeaveSummary(value)
      .then((response) => {
        //console.log("apiData", response);
        window.alert(response.data[0].Message);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
    setOpenPopup(false);
    getEmployeeLeaveSummary(selectedYear)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
    setIsLoading(false);
  };

  const handleDownload = (e) => {
    let table = document.getElementById("table-summary");
    TableToExcel.convert(table, {
      name: `employee_leaveSummary.xlsx`, // fileName
      sheet: {
        name: "Sheet 1", // sheetName
      },
    });
  };

  const handleYearChange = (event) => {
    const { value } = event.target;
    setSelectedYear(value);
  };

  const cancelLeaveSummary = () => {
    setOpenPopup(false);
  };

  return (
    <div className="container-fluid">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>Employee Leave Summary</Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className="card-header bg-primary text-white">
          Employee Leave Summary
        </div>

        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div className="row w-100">
              <label htmlFor="toDate" className="ml-4 p-1 text-muted">
                Year:
              </label>
              <div className="form-group">
                <select
                  className="form-control ml-1"
                  value={selectedYear}
                  onChange={handleYearChange}
                  id="status">
                  {yearsInDb.map((item) => (
                    <option key={item.Year} value={item.Year}>
                      {item.Year}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3 ml-auto">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                    id="txtSearch"
                    onChange={handleSearch}></input>
                </div>
              </div>
              <div className="">
                <div className="form-group">
                  <button
                    className=""
                    placeholder="download"
                    id="download"
                    title="Download as Excel Sheet"
                    onClick={handleDownload}>
                    <img
                      id="ExcelImage"
                      src={downloadBtn}
                      alt="exl_download_img"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="row w-100">
              <div
                className="ml-auto mt-1 mr-2"
                style={{
                  width: "17px",
                  height: "17px",
                  background: "rgba(0,255,0,0.5)",
                  border: "1px solid grey",
                }}></div>
              <div>
                <p>Enabled</p>
              </div>
              <div
                className="ml-4 mt-1 mr-2"
                style={{
                  width: "17px",
                  height: "17px",
                  background: "rgba(255,0,0,0.5)",
                  border: "1px solid grey",
                }}></div>
              <div>
                <p>Disabled</p>
              </div>
            </div>
          </div>
          <TblContainer id="summary">
            <TblHead />
            <TableBody>
              {/* use recordsAfterPagingAndSorting if pagination  requried */}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.idEmployee}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strEmpID}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.EmpName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Project}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.cl_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Casual Leave"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.cl_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.el_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Earned Leave"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.el_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.lop_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Loss of Pay"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.lop_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.mat_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Maternity"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.mat_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.pat_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Paternity"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.pat_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.b_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Bereavement"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.b_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.fh_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Floating holiday"]}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.fh_applicable ? "#1CD649" : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      background: item.comp_applicable
                        ? "rgb(0,255,0,0.5)"
                        : "rgb(255,0,0,0.5)",
                    }}>
                    <div
                      style={{
                        fontSize: "15px",
                        color: "black",
                        height: "30px",
                        paddingTop: "6px",
                      }}>
                      {item["Comp off"] + " (" + item.comp_ExpiryDate + ")"}
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "4px",
                        borderRadius: "60px",
                        background: item.comp_applicable
                          ? "#1CD649"
                          : "#DE1D37",
                      }}
                    ></div> */}
                  </TableCell>
                  {/* <TableCell
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    <Link
                      onClick={() => {
                        setSummaryRecord(item);
                        setOpenPopup(true);
                      }}
                      href="#"
                    >
                      Edit
                    </Link>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />

          <Popup
            openPopup={openPopup}
            title={"Update Leave Summary"}
            setOpenPopup={setOpenPopup}
            width={"sm"}
            setTopPadding={true}
            topPadding={0}>
            <UpdateLeaveSummary
              cancelLeaveSummary={cancelLeaveSummary}
              editSummaryRecord={editSummaryRecord}
              saveLeaveSummary={saveLeaveSummary}
            />
          </Popup>
        </div>
      </div>
    </div>
  );
}
