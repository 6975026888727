import React from 'react';

class NoPage extends React.Component {
    render() {
        return (
            <div>
                <h1>Page Not Found</h1>
            </div>
        )
    }
}

export default NoPage;