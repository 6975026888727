import React, { useState } from "react";

import { getUpdEmpDropdown } from "../../services/util";
import { useEffect } from "react";

import "./UpdateEmpList.css";

export default function UpdateEmpList(props) {
  const { cancelEmpList, editRecord, saveEmpList } = props;

  const [values, setValues] = useState(editRecord);

  const [roleDropdown, setRoleDropdown] = useState([]);
  const [designationDropdown, setDesignationDropdown] = useState([]);
  const [reportingDropDown, setReportingDropDown] = useState([]);

  const [isValid, setIsValid] = useState({
    EmpID: true,
    FirstName: true,
    LastName: true,
    Project: true,
    DOJ: true,
    EmailId: true,
    ContactNo: true,
    ReportingManager: true,
    Role: true,
    Designation: true,
    Status: true,
  });

  useEffect(() => {
    getUpdEmpDropdown()
      .then((response) => {
        setRoleDropdown(response.data.role);
        setDesignationDropdown(response.data.designation);
        setReportingDropDown(response.data.reporting);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  var message = "";
  const handleSave = (e) => {
    e.preventDefault();

    if (validate(values)) {
      if (window.confirm("Are you sure to save this?")) {
        saveEmpList(values);
      }
    } else {
      alert(message + "Please give valid inputs!");
      message = "";
    }
  };

  function validateEmail(email) {
    // Regular expression to validate email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Check if the email matches the regular expression
    if (emailRegex.test(email)) {
      // Check if the email contains '@ubtiinc.com'
      if (email.endsWith("@ubtiinc.com")) {
        return true;
      } else {
        message = "Check Email!\n";
        return false;
      }
    } else {
      message = "Check Email!\n";
      return false;
    }
  }

  function validateContactNo(contactNo) {
    const regex = /^\d{10}$/;
    return regex.test(contactNo);
  }

  function validate(values) {
    let finalFlag = true;
    const newIsValid = Object.assign({}, isValid);

    if (values["EmpID"] === null || values["EmpID"] === "") {
      newIsValid.EmpID = false;
      finalFlag = false;
    } else {
      newIsValid.EmpID = true;
    }
    if (values["FirstName"] === null || values["FirstName"] === "") {
      newIsValid.FirstName = false;
      finalFlag = false;
    } else {
      newIsValid.FirstName = true;
    }
    if (values["LastName"] === null || values["LastName"] === "") {
      newIsValid.LastName = false;
      finalFlag = false;
    } else {
      newIsValid.LastName = true;
    }
    if (values["Project"] === null || values["Project"] === "") {
      newIsValid.Project = false;
      finalFlag = false;
    } else {
      newIsValid.Project = true;
    }
    if (values["DOJ"] === null || values["DOJ"] === "") {
      newIsValid.DOJ = false;
      finalFlag = false;
    } else {
      newIsValid.DOJ = true;
    }
    if (!validateContactNo(values["ContactNo"])) {
      newIsValid.ContactNo = false;
      finalFlag = false;
    } else {
      newIsValid.ContactNo = true;
    }
    if (!validateEmail(values.EmailId)) {
      newIsValid.EmailId = false;
      finalFlag = false;
    } else {
      newIsValid.EmailId = true;
    }
    if (values["ReportEmpId"] === null || values["ReportEmpId"] === "") {
      newIsValid.ReportingManager = false;
      finalFlag = false;
    } else {
      newIsValid.ReportingManager = true;
    }
    if (values["UserRoleId"] === null || values["UserRoleId"] === "") {
      newIsValid.Role = false;
      finalFlag = false;
    } else {
      newIsValid.Role = true;
    }
    if (values["DesignationId"] === null || values["DesignationId"] === "") {
      newIsValid.Designation = false;
      finalFlag = false;
    } else {
      newIsValid.Designation = true;
    }
    if (values["EmpStatus"] === null || values["EmpStatus"] === "") {
      newIsValid.Status = false;
      finalFlag = false;
    } else {
      newIsValid.Status = true;
    }
    setIsValid(newIsValid);
    return finalFlag;
  }

  const handleCancel = (e) => {
    e.preventDefault();
    cancelEmpList();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      [id]: value,
    });
  };

  // const DateConvert = (inputDate) => {
  //   let date = new Date(inputDate);
  //   return new Intl.DateTimeFormat("en-IN").format(date);
  // };

  return (
    <form>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="EmpID">Employee ID:</label>
            <input
              type="number"
              className={"form-control " + (!isValid.EmpID && "is-invalid")}
              placeholder="Employee ID"
              id="EmpID"
              value={values.EmpID}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="FirstName">Employee First Name:</label>
            <input
              type="text"
              className={"form-control " + (!isValid.FirstName && "is-invalid")}
              placeholder="Employee First Name"
              id="FirstName"
              value={values.FirstName}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="LastName">Employee Last Name:</label>
            <input
              type="text"
              className={"form-control " + (!isValid.LastName && "is-invalid")}
              placeholder="Employee Last Name"
              id="LastName"
              value={values.LastName}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="Project">Project:</label>
            <input
              type="text"
              className={"form-control " + (!isValid.Project && "is-invalid")}
              placeholder="Project"
              id="Project"
              value={values.Project}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="DOJ">Date of Joining:</label>
            <input
              type="date"
              className={"form-control " + (!isValid.DOJ && "is-invalid")}
              placeholder="DOJ"
              id="DOJ"
              value={values.DOJ === null ? "" : values.DOJ.substring(0, 10)}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="EmailId">Email ID:</label>
            <input
              type="text"
              className={"form-control " + (!isValid.EmailId && "is-invalid")}
              placeholder="Email Id"
              id="EmailId"
              value={values.EmailId}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="ContactNo">Contact Number:</label>
            <input
              type="number"
              className={"form-control " + (!isValid.ContactNo && "is-invalid")}
              placeholder="Contact"
              id="ContactNo"
              value={values.ContactNo}
              onChange={handleInputChange}
            ></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="ReportEmpId">Reporting Manager:</label>
            <select
              className={
                "form-control " + (!isValid.ReportingManager && "is-invalid")
              }
              id="ReportEmpId"
              onChange={handleInputChange}
              value={values.ReportEmpId}
            >
              <option key="0" value={null}>
                Select..
              </option>
              {reportingDropDown.map((item) => (
                <option key={item.idEmployee} value={item.strEmpID}>
                  {item.strFirstName} ({item.strEmpID})
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="UserRoleId">Role:</label>
            <select
              className={"form-control " + (!isValid.Role && "is-invalid")}
              id="UserRoleId"
              onChange={handleInputChange}
              value={values.UserRoleId}
            >
              <option key="0" value={null}>
                Select..
              </option>

              {roleDropdown.map((item) => (
                <option key={item.idRole} value={item.idRole}>
                  {item.strRoleName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="DesignationId">Designation:</label>
            <select
              className={
                "form-control " + (!isValid.Designation && "is-invalid")
              }
              id="DesignationId"
              onChange={handleInputChange}
              value={values.DesignationId}
            >
              <option key="0" value={null}>
                Select..
              </option>

              {designationDropdown.map((item) => (
                <option key={item.idDesignation} value={item.idDesignation}>
                  {item.strDesigName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="EmpStatus">Status:</label>
            <select
              className={"form-control " + (!isValid.Status && "is-invalid")}
              id="EmpStatus"
              onChange={handleInputChange}
              value={values.EmpStatus}
            >
              <option value="Active">Active</option>
              <option value="InActive">InActive</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-md-6"></div>
        <div className="col-md-6" style={{ marginTop: "30px" }}>
          <button
            type="button"
            className="btn btn-primary font-weight-bold button"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary font-weight-bold button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-6"></div>
      </div>
    </form>
  );
}
