import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../pages/Home";

//import EmployeeDetail from "../pages/EmpDetail";
import EmployeeCreate from "../pages/Employee/EmpNew";

//Leave Management
import ApproveLeave from "../pages/LMS/ApproveLeave";
import LeaveSummary from "../pages/LMS/LeaveSummary";
import EmployeeList from "../pages/LMS/EmployeeList";
import LeaveRequest from "../pages/LMS/LeaveRequest";
import LeavePlan from "../pages/LMS/LeavePlans";
import EmployeeLeavePlan from "../pages/LMS/EmployeeLeavePlans";

//Change Password
import ChangePassword from "../pages/Password/ChangePassword";

//Page not found
import NoPage from "../pages/NoPage";
import "./menu.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import logo from "../assets/Images/Comp_logo.png";
import EmployeeLeaveRequests from "../pages/LMS/EmployeeLeaveRequests";

//import { authenticateUser } from "../services/util";

const MenuBar = (props) => {
  const [empDetail, setEmpDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = () => {
    setIsLoading(true);
    const userDetails = JSON.parse(sessionStorage.getItem("getUser"));
    setEmpDetail(userDetails);
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const logouthandler = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="row">
      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="w-100">
          <Router>
            <Navbar
              collapseOnSelect
              expand="lg"
              bg="primary"
              sticky="top"
              variant="dark">
              <Navbar.Brand href="/">
                <span className="img_span">
                  <img src={logo} className="img" alt="company_logo"></img>
                </span>
              </Navbar.Brand>
              <Navbar.Brand href="/">
                <span className="company">
                  <h4>Leave Management System</h4>
                </span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link href="/" className="active">
                    Home
                  </Nav.Link>
                  <NavDropdown
                    className="active"
                    title="Leave Management"
                    id="basic-nav-dropdown">
                    <NavDropdown.Item href="/LeaveRequest">
                      Apply Leave Request
                    </NavDropdown.Item>

                    {empDetail.strRoleName === "Manager" ||
                    empDetail.strRoleName === "HR" ? (
                      <>
                        <NavDropdown.Item href="/ApproveLeave">
                          Approve Leave Request
                        </NavDropdown.Item>
                      </>
                    ) : (
                      ""
                    )}
                    <NavDropdown.Item href="/LeavePlan">
                      My Leave Plans
                    </NavDropdown.Item>
                  </NavDropdown>
                  {empDetail.strRoleName === "Manager" ||
                  empDetail.strRoleName === "HR" ? (
                    <>
                      <NavDropdown
                        className="active"
                        title="Reports"
                        id="basic-nav-dropdown">
                        <NavDropdown.Item href="/EmpLeavePlan">
                          Employee Leave Plans
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/RequestSummary">
                          Employee Leave Requests
                        </NavDropdown.Item>
                        {empDetail.strRoleName === "HR" && (
                          <>
                            <NavDropdown.Item href="/LeaveSummary">
                              Employee Leave Summary
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/EmpList">
                              Employee List
                            </NavDropdown.Item>
                          </>
                        )}
                      </NavDropdown>
                    </>
                  ) : (
                    ""
                  )}
                  {/* <NavDropdown title="User Management" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/empList">
                      Employee List
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/addEmp">
                      Add Employee
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/assignRole">
                      Assign Role
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Admin" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/na">
                      Master Detail
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                <Nav>
                  {/* <Nav.Link className="active">
                    {empDetail.strRoleName}
                  </Nav.Link> */}
                  <NavDropdown
                    className="active mr-2"
                    title={empDetail.strFirstName || "Placeholder"}>
                    {/* <NavDropdown.Item>My profile</NavDropdown.Item> */}
                    <NavDropdown.Item href="/ChangePassword">
                      Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logouthandler}>
                      LogOut
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link href="#" className="active">
                    {empDetail.strFirstName}
                    {/* <div
                        style={{
                          backgroundColor: "white",
                          padding: "5px 5px 5px 5px",
                        }}
                      >
                        <img
                          src={ubtiLogo}
                          width="150"
                          height="25"
                          alt="Company Logo"
                        />
                      </div>
                  </Nav.Link> */}
                  {/* <Nav.Link href="/123">Logout</Nav.Link> */}
                </Nav>
                <div style={{ width: "5%" }}></div>
              </Navbar.Collapse>
            </Navbar>
            <br />
            {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
            <Switch>
              <Route exact path="/" component={Home} />
              {/* Employee */}
              <Route path="/addEmp" component={EmployeeCreate} />
              <Route path="/empDetail/:id?" component={EmployeeCreate} />
              {/* LMS */}
              <Route path="/LeavePlan" component={LeavePlan} />
              <Route path="/LeaveRequest" component={LeaveRequest} />
              <Route path="/ApproveLeave" component={ApproveLeave} />
              <Route path="/LeaveSummary" component={LeaveSummary} />
              <Route path="/RequestSummary" component={EmployeeLeaveRequests} />
              <Route path="/EmpLeavePlan" component={EmployeeLeavePlan} />
              <Route path="/EmpList" component={EmployeeList} />
              <Route path="/ChangePassword" component={ChangePassword} />
              {/* Page not found */}
              <Route component={NoPage}></Route>
            </Switch>
          </Router>
        </div>
      )}
    </div>
  );
};

export default MenuBar;
