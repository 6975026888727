import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import CustomTable from "../../components/DataTable/CustomTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  getLeaveStatus,
  getLeaveType,
  getFloatingHolidayList,
  InsertLeaveRequest,
  updateLeaveRequest,
  getYearsRequests,
  saveLeaveStatus,
} from "../../services/util";
import Popup from "../../components/Popup";
import ApplyLeave from "./ApplyLeave";

const headCells = [
  { id: "strFirstName", title: "Employee Name", enableSorting: false },
  { id: "leaveType", title: "Leave Type", enableSorting: false },
  {
    id: "strSubject",
    title: "Subject",
    enableSorting: false,
  },
  { id: "duration", title: "Duration", enableSorting: false },
  {
    id: "strReason",
    title: "Reason",
    enableSorting: false,
  },
  {
    id: "strStatus",
    title: "Status",
    enableSorting: false,
  },
  {
    id: "approver",
    title: "Approver",
    enableSorting: false,
  },
  {
    id: "strComment",
    title: "Comment",
    enableSorting: false,
  },
  { id: "action", title: "Action", enableSorting: false },
];

const initialValues = {
  status: "Pending",
  idEmp: "",
};

const userDetails = JSON.parse(sessionStorage.getItem("getUser"));
let firstFlag = true;

export default function LeaveRequest() {
  const [status, setStatus] = useState("Pending");
  const [records, setRecords] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState([]);
  const [floatingOPtions, setFloatingOptions] = useState([]);
  const [editLeaveRecord, setLeaveRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);

  const [yearsInDb, setYearsInDb] = useState([]);
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(
    today.getFullYear().toString()
  );

  useEffect(() => {
    if (firstFlag) {
      getYearsRequests()
        .then((response) => {
          setYearsInDb(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      firstFlag = false;
    }

    getLeaveStatus(userDetails.strEmpID, status, selectedYear)
      .then((response) => {
        setRecords(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  }, [status]);

  useEffect(() => {
    getLeaveType(userDetails.strEmpID)
      .then((response) => {
        setLeaveOptions(response.data);
      })
      .catch((error) => {
        alert(error.message);
      });
    getFloatingHolidayList(today.getFullYear().toString())
      .then((response) => {
        var newData = response.data.map((obj) => {
          //console.log(obj.dtHolidayDate);
          var dateVal = new Date(obj.dtHolidayDate);
          //obj.dtHolidayDate = dateVal.toISOString().split("T")[0];
          obj.dtHolidayDate = dateVal.toLocaleDateString("en-CA");
          return obj;
        });

        setFloatingOptions(newData);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = CustomTable(records, headCells, filterFn);

  const DateConvert = (inputDate) => {
    let date = new Date(inputDate);
    return new Intl.DateTimeFormat("en-IN").format(date);
  };

  const handleStatusChange = async (event) => {
    const { value } = event.target;
    setStatus(value);
    setIsLoading(true);
    await getLeaveStatus(userDetails.strEmpID, value, selectedYear)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        alert(error.message);
      });
    setIsLoading(false);
  };

  const handleYearChange = (event) => {
    const { value } = event.target;
    setSelectedYear(value);
  };

  const isBeforeOrEqualToday = (endDateStr) => {
    // Parse the end date string into a Date object
    var endDate = new Date(endDateStr);
    // Get today's date
    var today = new Date();
    // Set the time of today to 00:00:00
    today.setHours(0, 0, 0, 0);
    // Compare today's date with the end date
    if (today.getTime() <= endDate.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  const addLeaveRequest = async (value) => {
    setIsLoading(true);
    await InsertLeaveRequest(value)
      .then((response) => {
        if (response.data[0].Result === "Success") {
          alert(response.data[0].Message);
          setStatus("Pending");
          refreshTable();
          setOpenPopup(false);
        } else {
          alert(response.data[0].Message);
        }
        setIsLoading(false);
        //console.log(response.data[0].Result);
        //window.alert("Leave Applied Successfully!!!");
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const refreshTable = () => {
    let data = JSON.parse(sessionStorage.getItem("getUser"));
    getLeaveStatus(data.strEmpID, initialValues.status, selectedYear)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const saveLeaveRequest = async (value) => {
    setIsLoading(true);
    await updateLeaveRequest(value)
      .then((response) => {
        if (response.data[0].Result === "Success") {
          alert(response.data[0].Message);
          setStatus("Pending");
          refreshTable();
          setOpenPopup(false);
        } else {
          alert(response.data[0].Message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const cancelLeaveRequest = () => {
    setLeaveRecord(null);
    setOpenPopup(false);
  };

  let data = {};
  const statusLeave = (status, id) => {
    data = {
      idReq: id,
      empId: userDetails.strEmpID,
      status: status,
      comment: "",
    };
    updateStatus();
  };

  const updateStatus = () => {
    setIsLoading(true);
    //console.log(data);

    saveLeaveStatus(data)
      .then((response) => {
        alert("Cancelled Successfully!");
        setStatus("Pending");
        refreshTable();
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error.message);
        setIsLoading(false);
      });
    //window.location.reload();
  };

  return (
    <div className="container-fluid">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Management</Breadcrumb.Item>
        <Breadcrumb.Item active>Leave Request</Breadcrumb.Item>
      </Breadcrumb>

      <div className={isLoading === true ? "overlay" : "hideLoading"}>
        <div className="loader">Loading...</div>
      </div>
      <div className="card">
        <div className="card-header bg-primary text-white">
          Employee Leave Request
        </div>

        <div className="card-body ">
          <div className="row">
            <label htmlFor="year" className="ml-4 p-1 text-muted">
              Year:
            </label>
            <div className="form-group">
              <select
                className="form-control ml-1"
                value={selectedYear}
                onChange={handleYearChange}
                id="year">
                {yearsInDb.map((item) => (
                  <option key={item.Year} value={item.Year}>
                    {item.Year}
                  </option>
                ))}
              </select>
            </div>

            <label htmlFor="status" className="ml-4 p-1 text-muted">
              Status:
            </label>
            <div className="col-md-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={status}
                  onChange={handleStatusChange}
                  id="status">
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Un-Approved">Un-Approved</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
            <div className="col-md-6" style={{ paddingLeft: "0px" }}>
              {records.length < 1 ? (
                <span className="noRecord">No records found...</span>
              ) : null}
            </div>
            <div className="form-group ml-auto mr-3">
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => {
                  setOpenPopup(true);
                }}>
                Apply Leave
              </button>
            </div>
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {/* use recordsAfterPagingAndSorting if pagination  requried */}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.idLeaveRequest}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strFirstName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.strLeaveName}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.subject}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {DateConvert(item.startDate) +
                      " - " +
                      DateConvert(item.endDate)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.reason}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.status}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.approver}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.comment}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.status === "Pending" ||
                    (item.status === "Approved" &&
                      isBeforeOrEqualToday(item.endDate)) ? (
                      <>
                        <RouterLink
                          to="#"
                          onClick={(e) => {
                            if (
                              window.confirm("Are you sure want to cancel?")
                            ) {
                              statusLeave("Cancelled", item.idLeaveRequest);
                            }
                          }}>
                          Cancel
                        </RouterLink>
                      </>
                    ) : (
                      ""
                    )}
                    {item.status === "Pending" && <b> / </b>}
                    {item.status !== "Approved" ? (
                      <RouterLink
                        to="#"
                        onClick={() => {
                          setLeaveRecord(item);
                          setOpenPopup(true);
                        }}>
                        Edit
                      </RouterLink>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />

          {openPopup && (
            <Popup
              openPopup={openPopup}
              title={"Apply Leave"}
              setOpenPopup={setOpenPopup}
              width={"md"}
              setTopPadding={false}>
              <ApplyLeave
                employeeId={userDetails.strEmpID}
                leaveOptions={leaveOptions}
                floatingOPtions={floatingOPtions}
                addLeaveRequest={addLeaveRequest}
                cancelLeaveRequest={cancelLeaveRequest}
                editLeaveRecord={editLeaveRecord}
                saveLeaveRequest={saveLeaveRequest}
              />
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
}
