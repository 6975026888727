import React, { useEffect, useState } from "react";
import moment from "moment";
import { getLeaveCount } from "../../services/util";

const initialValues = {
  idLeaveRequest: "",
  idLeaveType: "",
  startDate: "",
  endDate: "",
  subject: "",
  halfDay: false,
  fileInput: "",
  reason: "",
  strEmpID: "",
};

export default function ApplyLeave(props) {
  const {
    employeeId,
    leaveOptions,
    floatingOPtions,
    addLeaveRequest,
    cancelLeaveRequest,
    editLeaveRecord,
    saveLeaveRequest,
  } = props;

  useEffect(() => {
    if (editLeaveRecord !== null && editLeaveRecord.idLeaveType !== 3) {
      if (editLeaveRecord.halfDay) {
        setDisableEndDate(true);
      }
      getLeaveCount(employeeId, editLeaveRecord.idLeaveType)
        .then((response) => {
          setLeaveCnt(response.data[0].totalLeaveCnt);
          //console.log(response.data[0]);
        })
        .catch((error) => {
          alert(error.message);
        });
    }
    if (editLeaveRecord !== null && editLeaveRecord.idLeaveType === 7) {
      setIsFloating(true);
    }
  }, []);

  initialValues.strEmpID = employeeId;

  const [values, setValues] = useState(
    editLeaveRecord != null ? editLeaveRecord : initialValues
  );

  const [leaveCnt, setLeaveCnt] = useState(null);

  const [disableEndDate, setDisableEndDate] = useState(
    editLeaveRecord != null ? false : true
  );

  const [isFloating, setIsFloating] = useState(false);

  const getDateDiff = (stDate, enDate) => {
    let date1 = new Date(stDate);
    let date2 = new Date(enDate);

    // The difference is in milliseconds
    let differenceInMilliseconds = date2 - date1;

    // Convert the difference from milliseconds to days
    let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays;
  };

  const validate = () => {
    let temp = {};
    temp.idLeaveType =
      values.idLeaveType.length !== 0 ? "" : "This field is required.";
    temp.startDate =
      values.startDate.length !== 0 ? "" : "This field is required.";
    temp.endDate = values.endDate.length !== 0 ? "" : "This field is required.";
    temp.reason = values.reason.length !== 0 ? "" : "This field is required.";
    //setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const leaveCountValidate = () => {
    if (values.idLeaveType != "3") {
      let dateDiff = getDateDiff(values.startDate, values.endDate);
      dateDiff = dateDiff + 1;
      if (dateDiff <= leaveCnt) {
        return true;
      } else if (leaveCnt === 0.5 && dateDiff === 1) {
        if (values.halfDay) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (leaveCountValidate()) {
        if (window.confirm("Are you sure to save this?")) {
          addLeaveRequest(values);
          //console.log(values);
          console.log(floatingOPtions);
        }
      } else {
        alert("You only have " + leaveCnt + " leave(s) balance!");
      }
    } else {
      alert("All the fields are mandatory!");
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (validate()) {
      if (true) {
        if (window.confirm("Are you sure to save this?")) {
          //console.log("AppLeave - Pop up", values);
          saveLeaveRequest(values);
        }
      } else {
        alert("You only have " + leaveCnt + " leave(s) balance!");
      }
    } else {
      alert("All the fields are mandatory!");
    }
  };

  const handleDropdownChange = (e) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      [id]: value,
    });
    if (value === "7") {
      setIsFloating(true);
    } else {
      setIsFloating(false);
    }
    getLeaveCount(employeeId, value)
      .then((response) => {
        setLeaveCnt(response.data[0].totalLeaveCnt);
        //console.log(response.data[0]);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleFloatDropDown = (e) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      startDate: value,
      endDate: value,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    cancelLeaveRequest();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "startDate") {
      setValues({ ...values, endDate: "", startDate: value });
      if (!values.halfDay) {
        setDisableEndDate(false);
      }
    } else {
      setValues({
        ...values,
        [id]: value,
      });
    }

    //if (validateOnChange) validate({ [name]: value });
  };

  const handleHalfDayChange = (e) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      startDate: value,
      endDate: value,
    });
  };

  const handleCheckInput = (e) => {
    if (e.target.checked) {
      setDisableEndDate(true);
      setValues({
        ...values,
        halfDay: e.target.checked,
        //startDate: "",
        endDate: values.startDate,
      });
    } else {
      setDisableEndDate(false);
      setValues({
        ...values,
        halfDay: e.target.checked,
      });
    }
  };
  const DateConvert = (inputDate) => {
    let date = new Date(inputDate);
    //return new Intl.DateTimeFormat("en-IN").format(date);
    return moment(date).format("YYYY-MM-DD");
  };

  //Get min date...
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 14);

  return (
    <form>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="leaveType">Leave:</label>
            <select
              className="form-control"
              value={values.idLeaveType}
              onChange={handleDropdownChange}
              id="idLeaveType">
              <option value="0">--Select--</option>
              {leaveOptions &&
                leaveOptions.map(function (item, i) {
                  return (
                    <option key={i} value={item.idLeaveType}>
                      {item.strLeaveName}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {isFloating ? (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="leaveType">Select Day:</label>
                <select
                  className="form-control"
                  id="startDate"
                  value={values.startDate}
                  onChange={handleFloatDropDown}>
                  <option value="0">--Select--</option>
                  {floatingOPtions &&
                    floatingOPtions.map(function (item, i) {
                      return (
                        <option key={i} value={item.dtHolidayDate}>
                          {item.dateText}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="startDate">Start Date:</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="dd/mm/yyyy"
                  id="startDate"
                  min={DateConvert(minDate)}
                  value={DateConvert(values.startDate)}
                  onChange={
                    values.halfDay ? handleHalfDayChange : handleInputChange
                  }></input>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="endDate">End Date:</label>
                <input
                  type="date"
                  name="endDate"
                  className="form-control"
                  placeholder="dd/mm/yyyy"
                  min={disableEndDate ? "" : DateConvert(values.startDate)}
                  value={DateConvert(values.endDate)}
                  onChange={
                    values.halfDay ? handleHalfDayChange : handleInputChange
                  }
                  disabled={disableEndDate}
                  id="endDate"></input>
              </div>
            </div>
          </>
        )}
        {/* <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              className="form-control"
              placeholder="dd/mm/yyyy"
              id="startDate"
              min={DateConvert(minDate)}
              value={DateConvert(values.startDate)}
              onChange={
                values.halfDay ? handleHalfDayChange : handleInputChange
              }></input>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              name="endDate"
              className="form-control"
              placeholder="dd/mm/yyyy"
              min={disableEndDate ? "" : DateConvert(values.startDate)}
              value={DateConvert(values.endDate)}
              onChange={
                values.halfDay ? handleHalfDayChange : handleInputChange
              }
              disabled={disableEndDate}
              id="endDate"></input>
          </div>
        </div> */}
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter subject"
              id="subject"
              value={values.subject}
              onChange={handleInputChange}
              maxLength="250"></input>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={values.halfDay}
                id="halfDay"
                checked={values.halfDay ? true : false}
                onChange={handleCheckInput}
                disabled={isFloating}
              />
              <label className="form-check-label" htmlFor="halfDay">
                Half Day
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <label htmlFor="reason">Reason:</label>
            <textarea
              className="form-control"
              type="text"
              id="reason"
              value={values.reason}
              onChange={handleInputChange}
              rows="4"></textarea>
          </div>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-6">
          {editLeaveRecord != null ? (
            <button
              type="button"
              className="btn btn-primary font-weight-bold button"
              onClick={handleSave}>
              Save
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary font-weight-bold button"
              onClick={handleSubmit}
              disabled={
                (leaveCnt !== null && leaveCnt > 0) ||
                values.idLeaveType === "3"
                  ? false
                  : true
              }>
              Submit
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary font-weight-bold button"
            onClick={handleCancel}>
            Cancel
          </button>

          {leaveCnt != null && values.idLeaveType !== "3" ? (
            <span style={{ fontWeight: "bold" }}>
              Leave Balance: {leaveCnt}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </form>
  );
}
